import Axios from 'axios';
import {mask} from 'vue-the-mask';
import moment from 'moment';

export default {
  directives: {mask},
  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      user: {
        name: '',
        profile: {
          country: {},
          state: {},
          city: {},
          nascimento: '',
        },
        network: {},
        financial: {},
        legal: {},
      },
      company: {
        community_flag: 0,
      },
      formPassword: '',
      address: {},
      errors: {},
      countries: [],
      states: [],
      cities: [],
      banks: [],
      selectedBank: '',
      logoFile: '',
      avatarFile: '',
      collapse: {
        one: true,
        two: false,
        three: false,
        four: false,
        five: false,
      },
      syncing: false,
    }
  },

  mounted() {
    var _this = this;

    if (_this.$store.state.role == 'admin') _this.$router.push({ path: '/dashboard/admin' });
    if (_this.$store.state.role == 'professional') _this.$router.push({ path: '/dashboard/professional' });

    $('[data-toggle="tooltip"]').tooltip();

    axios
    .get('/api/v1/users/' + this.me.id, {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.user = response.data.data;
        response.data.data.profile.nascimento = moment(this.user.profile.nascimento).format('YYYY-MM-DD');

        if (!this.user.profile.cpf || this.user.profile.cpf == "" ||
          !this.user.name || this.user.name == '') {
          $('#collapse-conta').addClass('show');
        }

      }
    });

    axios
    .get('/api/v1/companies/' + this.me.company_id, {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.company = response.data.data;
        this.address = (this.company.address) ? this.company.address[0] : {};

        if (!this.company.razao || this.company.razao == '' ||
          !this.company.cnpj || this.company.cnpj == '' ||
          !this.company.phone || this.company.phone == '') {
          $('#collapseThree').addClass('show');
        }

        if (!this.company.address || this.company.address.length < 1 ||
          (!this.company.address[0].city_id && !this.company.address[0].city_open)) {
          $('#collapse-local-company').addClass('show');
        }

      }
    });

    axios
    .get('/api/v1/countries', {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.countries = response.data.data;
        this.countries.unshift({"id":3,"code":"BR","phone":"55","name":"Brasil"});
      }
    });

    axios
    .get('/api/v1/states', {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.states = response.data.data;
        this.states.unshift({"id": 26,"code": "SP","name": "São Paulo","country_id": 3});
      }
    });

    axios
    .get('/api/v1/cities', {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.cities = response.data.data;
        this.cities.unshift({"id": 5034,"name": "São Paulo","state_id": 26});
      }
    });

    axios
    .get('/api/v1/banks', {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.banks = response.data.data;
      }
    });

  },

  methods: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },

    setBankId(id) {
      this.selectedBank = id;
    },

    handleAvatarUpload() {
      let size = this.$refs.avatar.files[0].size / 1024 / 1024;
      if (size > 2) this.errors.avatar.push("O arquivo é muito grande, reduza a imagem para menos de 2MB.");
      else this.errors.avatar = [];
      this.avatarFile = this.$refs.avatar.files[0];
    },

    handleLogoUpload() {
      let size = this.$refs.logo.files[0].size / 1024 / 1024;
      if (size > 2) this.errors.avatar.push("O arquivo é muito grande, reduza a imagem para menos de 2MB.");
      else this.errors.avatar = [];
      this.logoFile = this.$refs.logo.files[0];
    },

    submit() {
      let _this = this;
      _this.syncing = true;
      let formData = new FormData();
      // endereço
      if (this.address.country_id) formData.set("country", this.address.country_id);
      let state = this.address.country_id == 3 ? this.address.state_id : this.address.state_open;
      if (state != null) formData.set("state", state);
      let city = this.address.country_id == 3 ? this.address.city_id : this.address.city_open;
      if (city != null) formData.set("city", city);
      if (this.address.cep != null) formData.set("cep", this.address.cep);
      else formData.set("cep", '');
      if (this.address.street != null) formData.set("street", this.address.street);
      else formData.set("street", '');
      if (this.address.number != null) formData.set("number", this.address.number);
      else formData.set("number", '');
      if (this.address.complement != null) formData.set("complement", this.address.complement);
      else formData.set("complement", '');
      // dados da empresa
      if (this.company.nome_fantasia != null) formData.set("nome_fantasia", this.company.nome_fantasia);
      else formData.set("nome_fantasia", '');
      if (this.company.razao != null && this.company.razao != "") formData.set("razao", this.company.razao);
      if (this.company.site != null) formData.set("site", this.company.site);
      else formData.set("site", '');
      if (this.company.cnpj != null) formData.set("cnpj", this.company.cnpj);
      if (this.company.phone != null) formData.set("phone", this.company.phone);
      else formData.set("phone", '');
      // financeiro
      // TODO: financial_name
      // data.financial_name = this.company.financial_name;
      if (this.company.financial_email) formData.set("financial_email", this.company.financial_email);
      if (this.company.financial_name) formData.set("holder", this.company.financial_name);
      if (this.company.currency) formData.set("currency", this.company.currency);
      // user
      if (this.user.name != null) formData.set("name", this.user.name);
      if (this.user.last_name != null) formData.set("last_name", this.user.last_name);
      else formData.set("last_name", '');
      if (this.user.profile.cpf != null) formData.set("cpf", this.user.profile.cpf);
      else formData.set("cpf", '');
      if (this.user.profile.nascimento && this.user.profile.nascimento != null && this.user.profile.nascimento != 'Invalid date') formData.set("born_date", this.user.profile.nascimento);
      if (this.user.profile.phone != null) formData.set("user_phone", this.user.profile.phone);
      else formData.set("user_phone", '');
      // update email and password
      if (this.user.email && this.user.email != this.me.email) formData.set("email", this.user.email);
      if (this.formPassword != null && this.formPassword.length) formData.set("password", this.formPassword);
      // images
      if (this.logoFile) formData.append('logo', this.logoFile);
      if (this.avatarFile) formData.append('avatar', this.avatarFile);

      formData.append('_method', 'PUT');
      axios
      .post('/api/v1/companies/' + _this.company.id, formData, {
        headers: {
          Authorization: 'Bearer ' + _this.TOKEN,
          'Content-Type': 'multipart/form-data',
        }
      })
      .then(response => {
        // console.log(response.data);
        if (response.data.error) {
          _this.syncing = false;
          _this.errors = response.data.error;
          if (_this.errors.cnpj) alert('CNPJ já cadastrado.');
        } else {
          _this.$store.commit('setCompany', response.data.data);
          _this.$store.commit('setMe', response.data.data.users.find((e) => {return e.id == _this.me.id}));
          _this.$router.push({ path: '/dashboard/client' });
        }
      });
    },

    logout() {
      this.$store.commit('setToken', '');
      this.$store.commit('setMe', '');
      this.$router.push({ path: '/' });
    },

    deleteAccount() {
      axios
      .delete('/api/v1/users/' + this.me.id, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (response.data.error) {
          console.log(response.data.error);
          this.errors = response.data.error;
        } else {
          $('#delete-account-modal').modal('hide');
          this.$store.commit('setToken', '');
          this.$store.commit('setMe', '');
          this.$router.push({ path: '/' });
        }
      });
    },

    deleteCompany() {
      axios
      .delete('/api/v1/companies/' + this.me.company_id, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (response.data.error) {
          console.log(response.data.error);
          this.errors = response.data.error;
        } else {
          $('#delete-company-modal').modal('hide');
          this.$store.commit('setToken', '');
          this.$store.commit('setMe', '');
          this.$router.push({ path: '/' });
        }
      });
    },

    //Show Password
    showPassword() {
      var x = document.getElementById("new-password");

      jQuery('.ico-eye').toggleClass('show');


      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },

    handleCollapse(index) {
      switch (index) {
        case 1:
          this.collapse.one = !this.collapse.one;
          this.collapse.two = this.collapse.three = this.collapse.four = this.collapse.five = false;
          break;
        case 2:
          this.collapse.two = !this.collapse.two;
          this.collapse.one = this.collapse.three = this.collapse.four = this.collapse.five = false;
          break;
        case 3:
          this.collapse.three = !this.collapse.three;
          this.collapse.one = this.collapse.two = this.collapse.four = this.collapse.five = false;
          break;
        case 4:
          this.collapse.four = !this.collapse.four;
          this.collapse.one = this.collapse.two = this.collapse.three = this.collapse.five = false;
          break;
        case 5:
          this.collapse.five = !this.collapse.five;
          this.collapse.one = this.collapse.two = this.collapse.three = this.collapse.four = false;
          break;
        default:
          this.collapse.one = this.collapse.two = this.collapse.three = this.collapse.four = this.collapse.five = false;
          break;
      }
    },

  },

  computed: {

    avatar() {
      return (this.user.avatar) ? '/' + this.user.avatar : '/images/avatar.jpg';
    },

    owner() {
      return (this.user.id == this.company.users[0].id);
    }
  }

}
